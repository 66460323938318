import React from 'react'
import Breadcrumb from '../components/breadcrumb'

import Layout from '../components/layout'
import SEO from '../components/seo'
import IndexPage from '../components/indexPage'

const AccessibilityPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/accessibilite" type="index">
      <SEO 
        title="Les sites offrant la meilleure accessibilité de l'Observatoire"
        description="Quels sont les sites offrant la meilleure accessibilité de l'Observatoire de l'impact positif ? Contraste, typographie, balises ARIA, structures de pages…"
        path="/accessibilite" />

      <Breadcrumb url="/accessibilite" label="Accessibilité" />

      <h1 className="sr-only">Accessibilité</h1>
      <IndexPage slug="accessibility" type="index" />
    </Layout>
  )
  }

export default AccessibilityPage

